var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "modal-delete",
      attrs: {
        id: "modal-delete",
        "footer-class": "flex-nowrap",
        "no-close-on-backdrop": "",
        centered: ""
      },
      scopedSlots: _vm._u([
        {
          key: "modal-header",
          fn: function() {
            return [
              _c("h3", [
                _vm._v(
                  _vm._s(_vm.$t("modals.deletePersonalizationValue.title"))
                )
              ])
            ]
          },
          proxy: true
        },
        {
          key: "modal-footer",
          fn: function() {
            return [
              _c(
                "b-button",
                {
                  staticClass: "p-3 border-right-light-grey",
                  attrs: { block: "" },
                  on: { click: _vm.closeModal }
                },
                [_vm._v(" " + _vm._s(_vm.$t("buttons.cancel")) + " ")]
              ),
              _c(
                "b-button",
                {
                  staticClass: "p-3",
                  attrs: { variant: "primary", block: "" },
                  on: { click: _vm.deleteItem }
                },
                [_vm._v(" " + _vm._s(_vm.$t("buttons.ok")) + " ")]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "div",
        { staticClass: "delete-icon text-center" },
        [
          _c("b-icon", {
            staticClass: "mt-5 mb-4",
            attrs: { icon: "exclamation-triangle-fill", variant: "danger" }
          }),
          _c("div", {
            staticClass: "mt-2 mb-4 mx-3",
            domProps: {
              innerHTML: _vm._s(
                _vm.$t("modals.deletePersonalizationValue.message")
              )
            }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }