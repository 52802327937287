<template>
  <b-modal
      id="modal-delete"
      ref="modal-delete"
      footer-class="flex-nowrap"
      no-close-on-backdrop
      centered
  >
    <template #modal-header>
      <h3>{{ $t('modals.deletePersonalizationValue.title') }}</h3>
    </template>
    <div class="delete-icon text-center">
      <b-icon icon="exclamation-triangle-fill" variant="danger" class="mt-5 mb-4"></b-icon>
      <div class="mt-2 mb-4 mx-3" v-html="$t('modals.deletePersonalizationValue.message')"></div>
    </div>
    <template #modal-footer>
      <b-button
        class="p-3 border-right-light-grey"
        block
        @click="closeModal"
      >
        {{ $t('buttons.cancel') }}
      </b-button>
      <b-button
        variant="primary"
        class="p-3"
        block
        @click="deleteItem"
      >
        {{ $t('buttons.ok') }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: 'ModalDeletePersonalizationValue',
  mounted() {
    this.$refs['modal-delete'].show();
  },
  methods: {
    deleteItem() {
      this.$emit('delete-element');
      this.$refs['modal-delete'].hide();
    },
    closeModal() {
      this.$emit('close-modal');
      this.$refs['modal-delete'].hide();
    },
  },
};
</script>

<style scoped lang="scss">
.delete-icon svg {
  font-size: 80px;
}
</style>
